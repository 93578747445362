import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconSearch: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5074 15.3559C13.2896 16.3818 11.717 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.717 16.3818 13.2896 15.3559 14.5074L20.8485 20L19.9999 20.8485L14.5074 15.3559ZM15.8 10C15.8 13.2033 13.2033 15.8 10 15.8C6.79675 15.8 4.2 13.2033 4.2 10C4.2 6.79675 6.79675 4.2 10 4.2C13.2033 4.2 15.8 6.79675 15.8 10Z"
    />
  </Icon>
);
