import { FC } from 'react';

import { CircularProgress } from '../CircularProgress/CircularProgress';
import styles from './LoadingIndicator.module.scss';

export const LoadingIndicator: FC = () => (
  <div className={styles.loader}>
    <CircularProgress />
  </div>
);
