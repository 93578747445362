import { CAR_PURCHASE_STATUS } from 'src/constants';
import breakpoints from 'src/theme/breakpoints.export.module.scss';

import { PURCHASE_INFORMATION_STATUS } from '../../PurchaseInformation/PurchaseInformation';

export const isSmallScreen = () => {
  return window.innerWidth < parseInt(breakpoints['screen-sm'], 10);
};
export const isMediumScreen = () => {
  return window.innerWidth < parseInt(breakpoints['screen-md'], 10);
};

export const getCarPurchaseStatus = (
  cartExpired: boolean,
  carPurchaseStatus?: CAR_PURCHASE_STATUS,
): PURCHASE_INFORMATION_STATUS => {
  if (!cartExpired) {
    return PURCHASE_INFORMATION_STATUS.IN_PROGRESS;
  }
  return carPurchaseStatus === 'available'
    ? PURCHASE_INFORMATION_STATUS.AVAILABLE
    : PURCHASE_INFORMATION_STATUS.NOT_AVAILABLE;
};
