import classNames from 'classnames';
import { FC } from 'react';

import { IconCaretRightFilters } from 'src/general/Icons/IconCaretRightFilters';

import styles from './Chevron.module.scss';

export const Chevron: FC = () => (
  <span className={classNames(styles.root, 'chevron')}>
    <IconCaretRightFilters />
  </span>
);
