'use client';

import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import { ProfileApiResponse } from 'src/data/ProfileApi/ProfileApi.types';
import { IconAvatar } from 'src/general/Icons/IconAvatar';
import { getSha256Hash } from 'src/general/helpers/getSha256Hash';
import { getUserInitials } from 'src/general/helpers/getUserInitials';

import { CircularProgress } from '../CircularProgress/CircularProgress';
import styles from './UserAvatar.module.scss';

interface Props {
  loading?: boolean;
  notification?: boolean;
  userData: ProfileApiResponse | null;
}

export const UserAvatar: FC<Props> = ({ loading, notification, userData }) => {
  const userInitials = getUserInitials(userData);
  const [emailSha, setEmailSha] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;
    if (userData?.email) {
      getSha256Hash(userData.email.toLowerCase()).then((value) => {
        if (mounted) {
          setEmailSha(value);
        }
      });
    } else {
      setEmailSha(null);
    }

    return () => {
      mounted = false;
    };
  }, [userData?.email]);

  return (
    <div
      className={classNames(styles.root, 'UserAvatar', {
        [styles.notification]: notification,
        [styles.authenticated]: userData,
      })}
    >
      {loading && <CircularProgress className={styles.loader} />}
      <div className={classNames(styles.avatar, styles.avatarGeneric)}>
        <IconAvatar className={styles.avatarIcon} />
      </div>
      <div className={classNames(styles.avatar, styles.avatarProfile)}>
        {userInitials}
        {!!emailSha && (
          <img
            src={`https://gravatar.com/avatar/${emailSha}?d=blank`}
            alt=""
            loading="lazy"
            width={80}
            height={80}
            className={styles.avatarProfileImage}
          />
        )}
      </div>
    </div>
  );
};
