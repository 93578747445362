import { FC, ReactNode } from 'react';

interface Props {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
  wrapperAlternate?: (children: ReactNode) => ReactNode;
  children: ReactNode;
}

/** Conditionally add a wrapper element */
export const ConditionalWrapper: FC<Props> = ({ condition, wrapper, wrapperAlternate, children }) => {
  if (condition) {
    return wrapper(children);
  }
  if (typeof wrapperAlternate !== 'undefined') {
    return wrapperAlternate(children);
  }
  return children;
};
