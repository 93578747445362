import { FC, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { TransitionProps } from './transitions.type';

type Props = TransitionProps & {
  styles: { readonly [key: string]: string };
};

export const Transition: FC<Props> = ({
  children,
  className,
  styles,
  mountOnEnter = true,
  unmountOnExit = true,
  ...props
}) => {
  const nodeRef = useRef(null);
  const transitionDuration = parseInt(styles['transitionDuration'], 10);

  return (
    <CSSTransition
      {...props}
      timeout={transitionDuration}
      nodeRef={nodeRef}
      appear
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
    >
      <div ref={nodeRef} className={className}>
        {children}
      </div>
    </CSSTransition>
  );
};
