import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconHeart: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0052 6C11.9618 5.95941 11.9181 5.91835 11.874 5.87696C10.9524 5.01195 9.87437 4 7.99999 4C4.07153 4 1.18146 9 4.3471 13C7.51275 17 11.0026 19.5 12.0052 20C12.9967 19.5 16.4866 17 19.6529 13C22.8191 9 19.9276 4 16 4C14.1003 4 13.0115 5.03949 12.0975 5.91196C12.0666 5.94151 12.0358 5.97088 12.0052 6ZM12.0029 18.6061C13.2906 17.7614 16.1013 15.5534 18.712 12.2552C21.286 9.00337 18.8754 5.2 16 5.2C14.5851 5.2 13.8103 5.93795 12.8601 6.84297L12.0131 7.64958L11.186 6.87687C11.1607 6.85318 11.1355 6.8296 11.1104 6.80615C10.1543 5.91207 9.39288 5.2 7.99999 5.2C5.12411 5.2 2.71425 9.0031 5.28808 12.2553C7.90081 15.5567 10.7127 17.7639 12.0029 18.6061Z"
    />
  </Icon>
);
