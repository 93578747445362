import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconMenu: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path d="M4.3999 7.40002H19.5999V8.60002H4.3999V7.40002Z" />
    <path d="M4.3999 11.4H19.5999V12.6H4.3999V11.4Z" />
    <path d="M19.5999 15.4H4.3999V16.6H19.5999V15.4Z" />
  </Icon>
);
