import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconCircleCrossFilled: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM8.9999 8.15147L12 11.1516L15.0001 8.15147L15.8486 9L12.8485 12.0001L15.8484 15L14.9999 15.8485L12 12.8486L9.0001 15.8485L8.15158 15L11.1515 12.0001L8.15137 9L8.9999 8.15147Z"
    />
  </Icon>
);
