import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconSignOut: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path d="M12.3999 3.39999H16.9999C18.4358 3.39999 19.5999 4.56405 19.5999 5.99999V18C19.5999 19.4359 18.4358 20.6 16.9999 20.6H12.3999V19.4H16.9999C17.7731 19.4 18.3999 18.7732 18.3999 18V5.99999C18.3999 5.2268 17.7731 4.59999 16.9999 4.59999H12.3999V3.39999Z" />
    <path d="M11.4999 7.15147L15.9242 11.5757C16.1585 11.8101 16.1585 12.19 15.9242 12.4243L11.4999 16.8485L10.6514 16L14.0514 12.6H3.8999V11.4H14.0514L10.6514 8L11.4999 7.15147Z" />
  </Icon>
);
