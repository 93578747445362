import { isEmpty } from 'lodash';

import { ProfileApiResponse } from 'src/data/ProfileApi/ProfileApi.types';

export const getUserInitials = (userProfile: ProfileApiResponse | null): string => {
  if (!userProfile) {
    return '';
  }

  const initials: string[] = [];

  if (!isEmpty(userProfile.firstName)) {
    initials.push(userProfile.firstName.substring(0, 1));
  }
  if (!isEmpty(userProfile.lastName)) {
    initials.push(userProfile.lastName.substring(0, 1));
  }
  // In the case neither first or last name are available, use the email
  if (initials.length === 0) {
    initials.push(userProfile.email.substring(0, 1));
  }

  return initials.join('');
};
