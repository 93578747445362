import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconClock: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path d="M8.39989 7.78601V4.93341H7.59989V8.21415L10.1108 9.88811L10.5546 9.22247L8.39989 7.78601Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 8.00008C13.3333 10.9456 10.9455 13.3334 7.99996 13.3334C5.05444 13.3334 2.66663 10.9456 2.66663 8.00008C2.66663 5.05456 5.05444 2.66675 7.99996 2.66675C10.9455 2.66675 13.3333 5.05456 13.3333 8.00008ZM12.5333 8.00008C12.5333 10.5038 10.5036 12.5334 7.99996 12.5334C5.49627 12.5334 3.46663 10.5038 3.46663 8.00008C3.46663 5.49639 5.49627 3.46675 7.99996 3.46675C10.5036 3.46675 12.5333 5.49639 12.5333 8.00008Z"
    />
  </Icon>
);
