import { isNull } from 'lodash';

import { getOr } from 'src/utils/getOr';

export interface FormatNumberOptions {
  /** Add before the number */
  prepend?: string;
  /** Add after the number */
  append?: string;
  /** Specify the decimals */
  decimals?: number;
  /**
   * Skip any last decimals if they are 0.
   * eg: 1.00 => 1, 1.10 => 1.1
   */
  trimZeroDecimals?: boolean;
  /** Specify what to do in the case of a NaN */
  nan?: string;
}

export const formatNumber = (value: number | string, options?: FormatNumberOptions) => {
  if (`${value}` === 'NaN' && !isNull(options?.nan)) {
    return options!.nan!;
  }
  const decimals = getNumberOptionValue(options, 'decimals', 0);
  const prepend = getNumberOptionValue(options, 'prepend', '');
  const append = getNumberOptionValue(options, 'append', '');
  const trimZeroDecimals = getNumberOptionValue(options, 'trimZeroDecimals', false);

  let result = [
    prepend,
    (+`${value}`).toLocaleString('en-US', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    }),
    append,
  ].join('');

  if (decimals !== 0 && trimZeroDecimals) {
    // Remove any last decimals
    result = result.replace(/0*$/, '');
    // Remove the `.` character in the case of 1.00 => 1.
    result = result.replace(/\.$/, '');
  }

  return result;
};

const getNumberOptionValue = <T extends keyof FormatNumberOptions, R extends NoUndefined<FormatNumberOptions[T]>>(
  options: FormatNumberOptions | undefined,
  key: T,
  defaultValue: R,
): NoUndefined<FormatNumberOptions[T]> =>
  options ? getOr<R, FormatNumberOptions, string>(options, key, defaultValue) : defaultValue ?? defaultValue;
