import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconCaretUp: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.848 15-6.424-6.424a.6.6 0 0 0-.848 0L5.15 15l.849.849 6-6 6 6 .848-.849Z"
    />
  </Icon>
);
