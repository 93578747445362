'use client';

import classNames from 'classnames';
import { ComponentProps, FC, useState } from 'react';

import carmaLogo from 'src/assets/carma-logo.svg';
import { IconArrowLeft } from 'src/general/Icons/IconArrowLeft';
import { IconClose } from 'src/general/Icons/IconClose';
import { Fade } from 'src/general/transitions/Fade';
import { Slide } from 'src/general/transitions/Slide';

import { ButtonIcon } from '../../Button/ButtonIcon';
import { Image } from '../../Image/Image';
import { PurchaseInformation } from '../../PurchaseInformation/PurchaseInformation';
import { ScrollBox } from '../../ScrollBox/ScrollBox';
import { HeaderMenuData, HeaderMenuNavigationItem } from '../HeaderMenu.type';
import { SubmenuFaq } from '../Submenu/SubmenuFaq';
import styles from './Menu.module.scss';
import { MenuAccount } from './MenuAccount';
import { MenuRoot } from './MenuRoot';

interface Props {
  headerData: HeaderMenuData;
  isUserLoggedIn?: boolean;
  purchaseInformation: ComponentProps<typeof PurchaseInformation>;
  onItemClick?: () => void;
  desktopMenu?: boolean;
}

export const Menu: FC<Props> = ({
  headerData,
  isUserLoggedIn,
  onItemClick,
  purchaseInformation,
  desktopMenu = false,
}) => {
  const [currentMenu, setCurrentMenu] = useState<HeaderMenuNavigationItem | null>(null);

  return (
    <>
      {/* Root menu */}
      <Fade
        in={!currentMenu}
        className={classNames(styles.menuPanel, styles.menuPanelMain, { desktopMenu })}
        unmountOnExit={false}
        inert={currentMenu ? '' : undefined}
      >
        <aside className={styles.topBar}>
          <Image src={carmaLogo} alt="Carma" className={styles.logoImage} width="162" height="64" />
          <ButtonIcon
            Icon={IconClose}
            onClick={onItemClick}
            title="Close"
            styling="round"
            variant="tertiary"
            size="small"
          />
        </aside>
        <ScrollBox className={styles.scrollBox}>
          <PurchaseInformation {...purchaseInformation} />
          <MenuRoot
            headerData={headerData}
            setCurrentMenu={setCurrentMenu}
            onItemClick={onItemClick}
            className={styles.menuRoot}
          />
          {isUserLoggedIn && <MenuAccount onItemClick={onItemClick} className={styles.menuAccount} />}
        </ScrollBox>
      </Fade>
      {/* Sub-menu */}
      <Slide
        in={!!currentMenu}
        className={classNames(styles.menuPanel, { desktopMenu })}
        inert={!currentMenu ? '' : undefined}
      >
        <header className={styles.topBar}>
          <div className={styles.menuPanelTitle}>
            <ButtonIcon
              title="Back"
              Icon={IconArrowLeft}
              onClick={() => setCurrentMenu(null)}
              variant="tertiary"
              size="small"
              styling="round"
            />
            {!!currentMenu && <h2>{currentMenu.label}</h2>}
          </div>
        </header>
        <ScrollBox className={styles.scrollBox}>
          {currentMenu?.type === 'account' && <MenuAccount onItemClick={onItemClick} />}
          {currentMenu?.type === 'submenu_faq' && <SubmenuFaq onItemClick={onItemClick} submenu={currentMenu} />}
          {/* Add other menu types here */}
        </ScrollBox>
      </Slide>
    </>
  );
};
