import { unset } from 'lodash';
import { ComponentProps, FC, ReactNode } from 'react';

import { Button } from './Button';

type CTAButtonProps = Prettify<
  Omit<ComponentProps<typeof Button>, 'children'> & {
    label: ReactNode;
    buttonType?: 'button' | 'link';
  }
>;

/**
 * A variant of the Button component which allows the label to be set via the 'label' prop instead of via
 * children.
 * This is more convenient to use when the button label comes from an external source, e.g Contentful.
 */
export const CTAButton: FC<CTAButtonProps> = ({ label, buttonType, ...props }) => {
  if (buttonType === 'link') {
    // FIXME: Modal content is currently passed in Contentful even if the buttonType is set as link
    // Will need a better way to handle than having an unregistered prop
    unset(props, 'modalContent');
  }
  return <Button {...(props as any)}>{label}</Button>;
};
