import classNames from 'classnames';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { FC, useEffect, useRef, useState } from 'react';

import { IconClock } from 'src/general/Icons/IconClock';

import styles from './Countdown.module.scss';

export interface CountDownProps {
  countdown: Date | null;
}

dayjs.extend(duration);

export const Countdown: FC<CountDownProps> = ({ countdown }) => {
  const timeout = useRef<null | ReturnType<typeof setTimeout>>(null);
  const [timeLeft, setTimeLeft] = useState<string>('');
  const getTimeDifference = () => {
    if (countdown !== null) {
      return dayjs.duration(dayjs(countdown).diff(new Date()));
    }
    return null;
  };

  const updateTimeLeft = () => {
    const diff = getTimeDifference();
    if (diff === null || diff.asMilliseconds() <= 0) {
      setTimeLeft('0:00:00');
    } else {
      setTimeLeft(diff.format('H:mm:ss'));
      timeout.current = setTimeout(updateTimeLeft, 1000);
    }
  };

  useEffect(() => {
    updateTimeLeft();
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  });

  return (
    <section className={classNames(styles.root, { [styles.unavailable]: countdown === null })}>
      <div className={styles.imageWrapper}>
        <IconClock />
      </div>
      <div className={styles.countdown} data-testid="COUNTDOWN_TIME_LEFT">
        {timeLeft}
      </div>
    </section>
  );
};
