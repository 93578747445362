import { IndexItem } from 'src/general/helpers/fuzzySearchHelper';

export const getDefaultSearchUrl = (value: string): string =>
  value === '' ? '/used-cars' : `/used-cars?search=${encodeURIComponent(value)}`;

export const getDefaultSearchRecent = (value: string): IndexItem => ({
  key: `keyword:${value}`,
  type: 'keyword',
  label: `“${value}”`,
  value: value,
  url: getDefaultSearchUrl(value),
  order: 0,
  matchStrength: 0,
});
