import { debounce, throttle } from 'lodash';
import { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from 'react';

type OverflowDirections = 'top' | 'bottom' | 'left' | 'right';
type Overflows = {
  [direction in OverflowDirections]: boolean;
};

interface Props {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

const SCROLL_DELTA_TRIGGER = 12;

/**
 * Component detecting if it as any scroll overflows.
 * If a scroll overflows happens, it adds the class `overflow-{oveflowDirection}`.
 * Eg. `overflow-top`
 */
export const ScrollBox: FC<Props> = ({ className = '', children, style }) => {
  const refElement = useRef<HTMLDivElement>(null);
  const [overflows, setOverflows] = useState<Overflows>({ top: false, bottom: false, left: false, right: false });
  const classes = [className];
  const keys: OverflowDirections[] = Object.keys(overflows) as OverflowDirections[];
  keys.forEach((key) => {
    if (overflows[key]) {
      classes.push(`overflow-${key}`);
    }
  });

  const handleOverflows = () => {
    if (refElement.current) {
      const newOverflows = defineOverflows(refElement.current);
      setOverflows(newOverflows);
    }
  };
  const debouncedHandleOverflows = debounce(handleOverflows, 100);
  const throttledHandleOverflows = throttle(handleOverflows, 100);

  useEffect(() => {
    if (typeof window !== 'undefined' && refElement.current) {
      refElement.current.addEventListener('scroll', debouncedHandleOverflows);
      refElement.current.addEventListener('scroll', throttledHandleOverflows);
      window.addEventListener('resize', debouncedHandleOverflows);

      handleOverflows();
    }
    return () => {
      if (refElement.current) {
        refElement.current.removeEventListener('scroll', debouncedHandleOverflows);
        refElement.current.removeEventListener('scroll', throttledHandleOverflows);
        window.removeEventListener('resize', debouncedHandleOverflows);
      }
    };
  }, [children]);

  return (
    <div ref={refElement} className={classes.join(' ')} style={style} data-testid="ScrollBox">
      {children}
    </div>
  );
};

const defineOverflows = (element: HTMLDivElement): Overflows => ({
  top: isOverflowTop(element),
  bottom: isOverflowBottom(element),
  left: isOverflowLeft(element),
  right: isOverflowRight(element),
});

const isOverflowTop = (element: HTMLDivElement): boolean => Math.floor(element.scrollTop) >= SCROLL_DELTA_TRIGGER;
const isOverflowBottom = (element: HTMLDivElement): boolean =>
  element.scrollHeight > element.offsetHeight &&
  Math.ceil(element.scrollTop + element.offsetHeight) < element.scrollHeight - SCROLL_DELTA_TRIGGER;
const isOverflowLeft = (element: HTMLDivElement): boolean => Math.floor(element.scrollLeft) >= SCROLL_DELTA_TRIGGER;
const isOverflowRight = (element: HTMLDivElement): boolean =>
  element.scrollWidth > element.offsetWidth &&
  Math.ceil(element.scrollLeft + element.offsetWidth) < element.scrollWidth - SCROLL_DELTA_TRIGGER;
