import classNames from 'classnames';
import Link from 'next/link';
import { FC } from 'react';
import { shallow } from 'zustand/shallow';

import { IconSignOut } from 'src/general/Icons/IconSignOut';
import { authStore } from 'src/stores/authStore';

import styles from './Menu.module.scss';

interface Props {
  className?: string;
  onItemClick?: () => void;
}

const navItems: { label: string; href: string }[] = [
  {
    label: 'My purchases',
    href: '/account/purchases',
  },
  {
    label: 'Finance quotes',
    href: '/account/finance-quotes',
  },
  {
    label: 'Notification settings',
    href: '/account/notifications',
  },
  {
    label: 'Personal details',
    href: '/account/personal',
  },
];

export const MenuAccount: FC<Props> = ({ className, onItemClick }) => {
  const { userData } = authStore(({ userData }) => ({ userData }), shallow);

  return (
    <div className={className}>
      {!!userData && (
        <div className={styles.userData}>
          <p className={styles.userDataSalutations}>Hi {userData.firstName}</p>
          <p>{userData.email}</p>
        </div>
      )}
      <ul className={styles.menu}>
        {navItems.map((item) => (
          <li key={item.href}>
            <Link href={item.href} onClick={onItemClick} className={styles.menuItem}>
              {item.label}
            </Link>
          </li>
        ))}
        <li className={styles.listItemBorderTop}>
          <Link
            href="/api/auth/signout"
            onClick={onItemClick}
            className={classNames(styles.menuItem, styles.menuItemEndIcon)}
            data-testid="menu-popper-signout-section"
            // 🚨 Important: If prefetching the signout, the user is logged-out
            prefetch={false}
          >
            Sign out
            <IconSignOut />
          </Link>
        </li>
      </ul>
    </div>
  );
};
