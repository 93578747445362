import { useEffect } from 'react';

let scrollLocksCount = 0;

export const lockScroll = () => {
  if (scrollLocksCount === 0) {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }
  ++scrollLocksCount;
};
export const unlockScroll = () => {
  if (scrollLocksCount === 1) {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  }
  if (scrollLocksCount > 0) {
    --scrollLocksCount;
  }
};

/**
 * Hook to manage locking/unlocking scrolling of the body element, based on the given boolean.
 */
export const useScrollLock = (scrollLocked: boolean) => {
  useEffect(() => {
    if (scrollLocked) {
      lockScroll();
      return () => unlockScroll();
    }
  }, [scrollLocked]);
};
